/**
 * @file Filmdatenbank
 * Ein Plattform für Mitglieder & Firmen um ihre Filme zu veröffentlichen.
 * @author: Liip.ch
 * @version 1.0
 */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/";
import { createPinia } from "pinia";

//Setry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Styles
import "@/assets/styles/base.scss";
import "vue3-toastify/dist/index.css";

// Plugins
import i18n from "./i18n";
import Vue3Toastify from "vue3-toastify";

// Global components
import Multiselect from "vue-multiselect";
import SvgIcon from "@/components/SvgIcon.vue";

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;
  app.use(router);
  app.use(i18n);
  app.use(createPinia());

  app.use(Vue3Toastify);

  // Global components
  app.component("multiselect", Multiselect);
  app.component("SvgIcon", SvgIcon);

  const MONITORED_ENVIRONMENTS = ["staging", "production"];
  const SENTRY_DSN =
    "https://bcf9701c28b94b58ac0e985f71632a9f@o198669.ingest.sentry.io/6078947";
  if (MONITORED_ENVIRONMENTS.includes(import.meta.env.NODE_ENV)) {
    Sentry.attachErrorHandler(app, {
      logErrors: true
    });

    Sentry.init({
      app,
      environment: import.meta.env.NODE_ENV,
      dsn: SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [import.meta.env.VITE_API_URL]
        })
      ],
      tracesSampleRate: 1.0,
      release: import.meta.env.VITE_RELEASE_TAG
    });
  }

  app.mount("#app");
};

app = createApp(App);
setupApp(app);
